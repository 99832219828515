<template>
  <div class="page-layout-wrapper">
    <div class="container">
      <PageHeader
        title=".WAV POOL"
        subtitle="Register for an account below"
      />

      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <form @submit.prevent="handleSubmit">
            <div class="form-elements">
              <div class="form-group">
                <label for="email">Email address</label>
                <input :class="[{'is-invalid': (validation_errors.email != '')},'form-control']" v-model="email"
                       type="email" id="email">
                <div v-if="validation_errors.email" class="invalid-feedback">{{ validation_errors.email }}</div>
              </div>
              <div class="form-group">
                <label for="name">Full Name</label>
                <input :class="[{'is-invalid': (validation_errors.name != '')},'form-control']" v-model="name"
                       type="text" id="name">
                <div v-if="validation_errors.name" class="invalid-feedback">{{ validation_errors.name }}</div>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input :class="[{'is-invalid': (validation_errors.password != '')},'form-control']" v-model="password"
                       type="password" id="password">
                <div v-if="validation_errors.password" class="invalid-feedback">{{ validation_errors.password }}</div>
              </div>
              <div class="form-group">
                <label for="password_confirmation">Confirm Password</label>
                <input :class="[{'is-invalid': (validation_errors.password_confirmation != '')},'form-control']"
                       v-model="password_confirmation" type="password" id="password_confirmation">
                <div v-if="validation_errors.password_confirmation" class="invalid-feedback">
                  {{ validation_errors.password_confirmation }}
                </div>
              </div>

              <div class="form-group">
                <button
                  class="btn btn-block"
                  v-text="loading ? 'Registering...' : 'Register'"
                />
              </div>

              <transition name="fade-fast">
                <div class="form-elements__blocker" v-show="loading"/>
              </transition>
            </div>
          </form>
          <div class="text-center">
            <div class="mb-2"><small>Already have an account?</small></div>
            <router-link to="/login" class="pill-button pill-button--outline">Login Here</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Page/PageHeader.vue'
import {mapActions} from "vuex";

export default {
  name: 'Register',
  components: {
    PageHeader
  },
  data: () => ({
    email: '',
    name: '',
    password: '',
    password_confirmation: '',
    validation_errors: {
      email: '',
      name: '',
      password: '',
      password_confirmation: ''
    },
    loading: false
  }),
  methods: {
    ...mapActions('auth', [
      'setSession'
    ]),
    handleSubmit() {
      this.loading = true

      this.$http
        .post('/register', {
          email: this.email,
          name: this.name,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
        .then(response => {
          if (response.data.message !== 'success') {
            throw Error("Registration was not successful");
          }

          // user is now logged in, redirect to dashboard route '/'
          this.setSession({ isAuthenticated: true, user: response.data.user })
            .then(() => {
              const path = this.user.role_key === 'admin' ? '/admin' : '/';

              this.$router.push({ path })
            });
        })
        .catch(response => {
          Object.entries(response.data.validation_errors).forEach((el, i) => {
            this.validation_errors[el[0]] = el[1].join(' ')
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
